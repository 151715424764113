import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/globals.css");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/header/Fetcher.tsx");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/header/HeaderLogin.tsx");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/header/nav.tsx");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/images/icon_arrow_right_up.svg");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/images/icon_arrow_right.svg");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/images/icon_dan.svg");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/app/images/icon_metamask.svg");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/root/www/sourcedao/next/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/Poppins.ttf\",\"display\":\"swap\"}],\"variableName\":\"font\"}")